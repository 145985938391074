
*{

	box-sizing: border-box;
}

@font-face { font-family: Oxygen-Light; font-weight: 400; src: url('../fonts/Oxygen-Light.ttf');}
@font-face { font-family: Oxygen-Regular; font-weight: 400;src: url('../fonts/Oxygen-Regular.ttf'); } 
@font-face { font-family: Oxygen-Bold; font-weight: bold; src: url('../fonts/Oxygen-Bold.ttf');}
@font-face { font-family: Gotham-Rounded-Medium; font-weight: 400; src: url('../fonts/Gotham Rounded Medium.otf');}

// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b{
	font-family: Oxygen-Bold;
}

html, body {
  margin:0;
}

body {
  margin:0;
  background-color: #F6F6F6;
}

input:focus {
	outline-width: 0;
	border: 2px solid #2E715D !important;
}

input {
  outline:none;
  font-size: 15px;
 }

button{
	font-family: Oxygen-Bold;
	font-size: 1.1em;
	border-radius: 10px;
	padding: 15px;
	box-shadow: none;
	border:none;
	margin-top: 10px;
	margin-bottom: 5px;
	cursor: pointer;
}

button.long{
	width: 80%;
  margin: 15px auto 12px;
  max-width: 340px;
}

button.primary{
	color: white;
	background-color:#2E715D;
}

button.secondary{
	color: #2E715D;
	background-color:#EAF0EE;
}

button:not(.has-loader):disabled{
	color:white;
	background-color: #B5B5B5;
}

button.fit{
  width: 100%;
  max-width: 340px;
}


button.m2{
	margin: 2% auto;
}

button.no-m{
	margin:0;
}

button > i{
	margin: 0 15px;
}

button:hover{
	opacity: .8;
}

button:focus {
  outline: none;
}

.p-t-0 {
  margin-top: 0 !important;
}

.full-width{
  width: 100% !important;
}

.full-opacity{
  opacity: 1 !important;
}

@media all and (max-width:  768px){

	button{
		margin: 10px 0;
	}

}

@media all and (max-width:  396px){
	button{
		font-size: 1em;
	}
}

// .rccs__card--unknown > .rccs__card--front {
//   // background: url(/static/media/credit-card-front.532b0ec0.png) !important;
//   background: url(../img/card/cardlines/png/credit-card-front.png) !important;
//   background-size: cover !important;
// }

// .rccs__card--unknown > .rccs__card--back {
//   // background: url(/static/media/credit-card-front.532b0ec0.png) !important;
//   background: url(../img/card/cardlines/png/credit-card-back.png) !important;
//   background-size: cover !important;
// }

// .rccs__chip {
//   display: none;
// }

.rccs__card--unknown > div {
  color: #555;
}